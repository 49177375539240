<template>
  <div class="MBcontnet320">
    <a target="_blank" href="https://www.canceraway.org.tw/">
      <img
        src="../assets/mobile/320/logofooter.png"
        srcset="
          ../assets/mobile/320/logofooter@2x.png 2x,
          ../assets/mobile/320/logofooter@3x.png 3x
        "
        id="logo"
    /></a>

    <img
      src="../assets/mobile/414/p1.png"
      srcset="
        ../assets/mobile/414/p1@2x.png 2x,
        ../assets/mobile/414/p1@3x.png 3x
      "
      id="title"
    />
    <div class="paragragh">
      <img
        src="../assets/mobile/414/p2.png"
        srcset="
          ../assets/mobile/414/p2@2x.png 2x,
          ../assets/mobile/414/p2@3x.png 3x
        "
        id="content"
      />
      <p style="margin-top:10px">
        近年來，隨著治療癌症的原廠生物製劑藥物專利陸續到期，其他藥廠紛紛開發與原廠生物製劑相仿的「生物相似藥」生物製劑，提供病友不同用藥選擇。
        <br /><br />
        過去生物相似藥的開發，最常用於發炎性自體免疫疾病之治療，而近日國內陸續有生物相似藥使用於癌症標靶治療。
        <br /><br />
        面臨生物相似藥，病友可能面臨什麼問題？又該如何了解自身權益？透過以下問題集，讓台灣癌症基金會帶著你，從生物製劑、原廠生物製劑與生物相似藥開始認識，一起為自己的用藥安全把關。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "MBcontnet320",
};
</script>
<style scoped>
.MBcontnet320 {
  width: 100%;
  height: auto;
}
#logo {
  width: 45vw;
  height: auto;
  margin: 2% auto;
}
#title {
  width: 85%;
  height: auto;
  margin: 0 auto -3px auto;
  display: block;
}
.paragragh {
  width: 100%;
  height: auto;
  margin: 0 auto;
  display: block;
  text-align: center;
}
#content {
  margin: 0 auto -560px auto;
  position: relative;
  z-index: 1;
}
p {
  text-align: left;
  margin: 3.3% auto 2% auto;
  width: 295px;
  font-size: 17.5px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: 2px;
  color: #4c75ad;
  position: relative;
  z-index: 3;
}
</style>
