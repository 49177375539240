<template>
  <div class="qna">
    <div class="qalist" id="qa1">
      <div
        class="closedStatus"
        @click="showtheLog1()"
        v-show="this.qa1 == false"
      >
        <img
          src="../assets/laptop/1024_q1/title+.png"
          srcset="
            ../assets/laptop/1024_q1/title+@2x.png 2x,
            ../assets/laptop/1024_q1/title+@3x.png 3x
          "
          id="closelog"
        />
      </div>
      <div class="openStatus" v-show="this.qa1">
        <img
          src="../assets/laptop/1024_q1/title-.png"
          srcset="
            ../assets/laptop/1024_q1/title-@2x.png 2x,
            ../assets/laptop/1024_q1/title-@3x.png 3x
          "
          class="blocker"
          @click="showtheLog1()"
        />
        <div class="openlog slideblue">
          <p style=" z-index:5; position:relative;">
            目前最常見的生物藥（Biologic），是癌症與自體免疫性疾病重要的治療藥品，是一種透過基因工程，由活細胞製造的蛋白質藥，如：治療性蛋白質（可取代或強化體內功能性蛋白質，例如治療糖尿病的胰島素）、單株抗體（能精準攻擊特定疾病標的，例如治療乳癌的賀癌平）。
            <br /><br />
            由活細胞製作的生物藥，和由化學反應製作的藥品有極大不同，生物藥的分子量大很多、結構也更為複雜。
          </p>
        </div>
      </div>
    </div>
    <div class="qalist" id="qa2">
      <div
        class="closedStatus"
        @click="showtheLog2()"
        v-show="this.qa2 == false"
      >
        <img
          src="../assets/laptop/1024_q2/title+.png"
          srcset="
            ../assets/laptop/1024_q2/title+@2x.png 2x,
            ../assets/laptop/1024_q2/title+@3x.png 3x
          "
          id="closelog"
        />
      </div>
      <div class="openStatus" v-show="this.qa2">
        <img
          src="../assets/laptop/1024_q2/title-.png"
          srcset="
            ../assets/laptop/1024_q2/title-@2x.png 2x,
            ../assets/laptop/1024_q2/title-@3x.png 3x
          "
          class="blocker"
          @click="showtheLog2()"
        />
        <div class="openlog slideblue">
          <p style=" z-index:5; position:relative;">
            藥品當原廠生物藥專利期過後，其他藥廠可以模仿原廠生物藥的分子結構與生物特性，並利用已公開的數據，研發生產具有相等臨床療效的生物藥，即為「生物相似藥」。
          </p>
          <iframe
            width="690"
            height="384"
            style="z-index:7;position:relative;margin-top: 10px;"
            src="https://www.youtube.com/embed/VU2mBPA-Wuc?start=2"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <p class="iframesrc" style="margin-bottom:38px;">
            影片來源／照護線上
          </p>
        </div>
      </div>
    </div>
    <div class="qalist" id="qa3">
      <div
        class="closedStatus"
        @click="showtheLog3()"
        v-show="this.qa3 == false"
      >
        <img
          src="../assets/laptop/1024_q3/title+.png"
          srcset="
            ../assets/laptop/1024_q3/title+@2x.png 2x,
            ../assets/laptop/1024_q3/title+@3x.png 3x
          "
          id="closelog"
        />
      </div>
      <div class="openStatus" v-show="this.qa3">
        <img
          src="../assets/laptop/1024_q3/title-.png"
          srcset="
            ../assets/laptop/1024_q3/title-@2x.png 2x,
            ../assets/laptop/1024_q3/title-@3x.png 3x
          "
          class="blocker"
          @click="showtheLog3()"
        />
        <div class="openlog slideblue">
          <p style="z-index:5; position:relative;">
            原廠生物藥與生物相似藥都屬於生物藥，兩者結構相似。然而生物藥(包含原廠藥與相似藥)製程複雜，不同批號間很難完全相同，與結構較簡單的學名藥不同。
            <br /><br />
            因此在品質、療效與安全性都需要有比學名藥更嚴謹的機制來把關，生物相似藥須完成臨床試驗，證明其療效、安全性皆和原廠參考藥品相等，經過完整審查才能上市。
          </p>
        </div>
      </div>
    </div>
    <div class="qalist" id="qa4">
      <div
        class="closedStatus"
        @click="showtheLog4()"
        v-show="this.qa4 == false"
      >
        <img
          src="../assets/laptop/1024_q4/title+.png"
          srcset="
            ../assets/laptop/1024_q4/title+@2x.png 2x,
            ../assets/laptop/1024_q4/title+@3x.png 3x
          "
          id="closelog"
        />
      </div>
      <div class="openStatus" v-show="this.qa4">
        <img
          src="../assets/laptop/1024_q4/title-.png"
          srcset="
            ../assets/laptop/1024_q4/title-@2x.png 2x,
            ../assets/laptop/1024_q4/title-@3x.png 3x
          "
          class="blocker"
          @click="showtheLog4()"
        />
        <div class="openlog slideblue">
          <p style=" z-index:5; position:relative;">
            台灣生物相似
            藥在定義、命名上皆循用歐盟的邏輯，以「商品名與學名」區別生物相似藥，而學名後不加註記來標示，因此要區分原廠生物藥或生物相似藥應該以商品名與學名一同呈現。
            <br /><br />
            建議病友可以直接向醫師詢問，或記錄自己使用的生物藥品之商品名與學名（中英文皆可)，再於食品藥物管理署之西藥、醫療器材、特定用途化粧品<a
              target="_blank"
              href="https://canceraway.pse.is/KC85P"
              class="hyperblank"
              >許可證平台
              <img
                src="../assets/link/link20.png"
                srcset="../assets/link/link20@2x.png"
                class="hyperchain"/></a
            >查詢。
          </p>
        </div>
      </div>
    </div>
    <div class="qalist" id="qa5">
      <div
        class="closedStatus"
        @click="showtheLog5()"
        v-show="this.qa5 == false"
      >
        <img
          src="../assets/laptop/1024_q5/title+.png"
          srcset="
            ../assets/laptop/1024_q5/title+@2x.png 2x,
            ../assets/laptop/1024_q5/title+@3x.png 3x
          "
          id="closelog"
        />
      </div>
      <div class="openStatus" v-show="this.qa5">
        <img
          src="../assets/laptop/1024_q5/title-.png"
          srcset="
            ../assets/laptop/1024_q5/title-@2x.png 2x,
            ../assets/laptop/1024_q5/title-@3x.png 3x
          "
          class="blocker"
          @click="showtheLog5()"
        />
        <div class="openlog slideblue">
          <p style="z-index:5; position:relative;">
            生物相似藥是原廠生物藥的仿製品，所有臨床療效、安全性都必須與原廠藥相當，才能申請上市。原廠生物藥上市時，是唯一的藥品，所有的療效都是未知的，因此在上市前與取得每一個適應症前，皆需經過完整的臨床試驗，證實它的療效與安全性。
            <br /><br />
            生物相似藥的第三期臨床試驗，目的不在建立生物相似藥自己的療效，而在證實其結構與生物功能雖然是高度相似，仍然可以達到與原廠生物藥沒有差別的療效，因此不需要進行每一個適應症的人體試驗，而可以在合理的範圍內與食藥署同意下，類推到沒有進行人體試驗的適應症。
          </p>
        </div>
      </div>
    </div>
    <div class="qalist" id="qa6">
      <div
        class="closedStatus"
        @click="showtheLog6()"
        v-show="this.qa6 == false"
      >
        <img
          src="../assets/laptop/1024_q6/title+.png"
          srcset="
            ../assets/laptop/1024_q6/title+@2x.png 2x,
            ../assets/laptop/1024_q6/title+@3x.png 3x
          "
          id="closelog"
        />
      </div>
      <div class="openStatus" v-show="this.qa6">
        <img
          src="../assets/laptop/1024_q6/title-.png"
          srcset="
            ../assets/laptop/1024_q6/title-@2x.png 2x,
            ../assets/laptop/1024_q6/title-@3x.png 3x
          "
          class="blocker"
          @click="showtheLog6()"
        />
        <div class="openlog slideblue">
          <p style="z-index:5; position:relative;">
            生物相似藥與原廠生物藥在取得許可證上市之前，已經被證實在療效與安全性方面相等。然而，因為生物相似藥仍然與原廠藥在結構上有些微的差異，除了可能有免疫特性的反應之外，其他副作用都必須與原廠生物藥相仿。
            <br /><br />
            額外的副作用是有可能的。因此，上市之後的安全性追蹤是必要的，食藥署會根據原廠生物藥多年來累積的安全性資料訂定的追蹤項目，也要蒐集非預期的副作用。例如，用於治療自體免疫疾病的單株抗體藥品，過去原廠生物藥的使用中，我們已經知道可能增加感染的機率，所以，其生物相似藥也必須進行這類副作用的監測。
            <br /><br />
            然而，使用任何藥品出現副作用，應主動與主治醫師討論，多數副作用都有因應對策，和主治醫師做全面性討論、評估，以正面的態度來面對療程。醫療人員同時會視藥品不良反應情形，通報全國藥品不良反應通報系統。當懷疑因藥品引起的嚴重不良反應發生時，醫療機構、藥局、藥商應依規定，在期限內通報全國藥品不良反應系統，民眾亦可直接主動通報，共同維護用藥安全。
          </p>
        </div>
      </div>
    </div>
    <div class="qalist" id="qa7">
      <div
        class="closedStatus"
        @click="showtheLog7()"
        v-show="this.qa7 == false"
      >
        <img
          src="../assets/laptop/1024_q7/title+.png"
          srcset="
            ../assets/laptop/1024_q7/title+@2x.png 2x,
            ../assets/laptop/1024_q7/title+@3x.png 3x
          "
          id="closelog"
        />
      </div>
      <div class="openStatus" v-show="this.qa7">
        <img
          src="../assets/laptop/1024_q7/title-.png"
          srcset="
            ../assets/laptop/1024_q7/title-@2x.png 2x,
            ../assets/laptop/1024_q7/title-@3x.png 3x
          "
          class="blocker"
          @click="showtheLog7()"
        />
        <div class="openlog slideblue">
          <p style=" z-index:5; position:relative;">
            因為生物相似藥與原廠生物藥具有相等的療效，而且在開發的成本上較低，所以健保也給付生物相似藥，目前生物相似藥的健保給付價為原廠生物藥的65-85%，選用生物相似藥將可以為健保節省可觀的花費，讓健保有機會永續經營。
            <br /><br />
            根據國內法規，生物相似藥的適應症，須與原廠生物藥的核准適應症相符。部分適應症可能會受到健保整體的財務影響而未給付，生物相似藥的自費價格也一定會低於原開發廠的價格，對於財務負擔較重的病友，可考量接受價格較低但是療效與副作用與原廠藥相當之生物相似藥的治療。
          </p>
        </div>
      </div>
    </div>
    <div class="qalist" id="qa8">
      <div
        class="closedStatus"
        @click="showtheLog8()"
        v-show="this.qa8 == false"
      >
        <img
          src="../assets/laptop/1024_q8/title+.png"
          srcset="
            ../assets/laptop/1024_q8/title+@2x.png 2x,
            ../assets/laptop/1024_q8/title+@3x.png 3x
          "
          id="closelog"
        />
      </div>
      <div class="openStatus" v-show="this.qa8">
        <img
          src="../assets/laptop/1024_q8/title-.png"
          srcset="
            ../assets/laptop/1024_q8/title-@2x.png 2x,
            ../assets/laptop/1024_q8/title-@3x.png 3x
          "
          class="blocker"
          @click="showtheLog8()"
        />
        <div class="openlog slideblue">
          <p style=" z-index:5; position:relative;">
            治療期間的用藥安全，需要病醫共同努力，生物藥本身的結構、生產方式與作用機制比較複雜，但是經過食藥署核准的生物藥，不論是原廠生物藥或生物相似藥，都已經被證實有效安全且療效相等，因此病人與醫師可以完全根據病人的需求決定用藥。
            <br /><br />
            尤其是對於癌症病友來說，治療往往只有一次機會，病友與醫師須共同討論，病友自己要理解病程發展、不要害怕發問，主動瞭解治療計畫、積極加入討論，與醫師共同努力，才能讓治療過程更順利。
          </p>
        </div>
      </div>
    </div>
    <div class="qalist" id="qa9">
      <div
        class="closedStatus"
        @click="showtheLog9()"
        v-show="this.qa9 == false"
      >
        <img
          src="../assets/laptop/1024_q9/title+.png"
          srcset="
            ../assets/laptop/1024_q9/title+@2x.png 2x,
            ../assets/laptop/1024_q9/title+@3x.png 3x
          "
          id="closelog"
        />
      </div>
      <div class="openStatus" v-show="this.qa9">
        <img
          src="../assets/laptop/1024_q9/title-.png"
          srcset="
            ../assets/laptop/1024_q9/title-@2x.png 2x,
            ../assets/laptop/1024_q9/title-@3x.png 3x
          "
          class="blocker"
          @click="showtheLog9()"
        />
        <div class="openlog slideblue">
          <p style=" z-index:5; position:relative;">
            可互換的概念與落實執行下，醫師可為病人處方藥品的轉換。台灣食藥署的生物相似藥相關法規是以歐盟的法規為基礎，因此在台灣，當食藥署核准了生物相似藥，即意味了它的可互換性。
            <br /><br />
            在療程一開始或進行中，醫師皆可在臨床評估後，與病人充分諮詢溝通，在病人知情且同意的情況下互換使用。
          </p>
        </div>
      </div>
    </div>
    <div class="qalist" id="qa10">
      <div
        class="closedStatus"
        @click="showtheLog10()"
        v-show="this.qa10 == false"
      >
        <img
          src="../assets/laptop/1024_q10/title+.png"
          srcset="
            ../assets/laptop/1024_q10/title+@2x.png 2x,
            ../assets/laptop/1024_q10/title+@3x.png 3x
          "
          id="closelog"
        />
      </div>
      <div class="openStatus" v-show="this.qa10">
        <img
          src="../assets/laptop/1024_q10/title-.png"
          srcset="
            ../assets/laptop/1024_q10/title-@2x.png 2x,
            ../assets/laptop/1024_q10/title-@3x.png 3x
          "
          class="blocker"
          @click="showtheLog10()"
        />
        <div class="openlog slideblue">
          <p class="mainparagraph" style="margin-top: 50px;">
            <b
              >更換前，醫師應與病人充分諮詢溝通，以下幾項諮詢要點供病友參考：</b
            >
          </p>
          <p class="subparagraph">
            1. 詢問醫師，為什麼要換藥？<br />
            2. 換藥之後，我會有額外增加的藥費嗎？ <br />
            3. 我有機會選擇用原廠生物藥或是生物相似藥嗎？<br />
            4. 用藥後如果有嚴重副作用，我可以怎麼辦？有什麼機構可以通報嗎？<br />
            5.
            我的疾病使用這個生物藥，是否有健保給付？如果是自費使用，我用原廠生物藥<br />
            <span style="margin-left: 29px"
              >與生物相似藥，到完成療程，各需要多少花費？</span
            ><br /><br />
          </p>
          <p class="mainparagraph">
            台灣對生物相似藥的管制剛起步，藥品上市前須先通過食藥署的查驗登記並取得許可證，若病友想了解藥品詳情，可於衛生福利部食品藥物管理署西藥、醫療器材、特定用途化粧品<a
              target="_blank"
              href="https://canceraway.pse.is/KC85P"
              class="hyperblank"
              >許可證平台
              <img
                src="../assets/link/link17.png"
                srcset="../assets/link/link17@2x.png"
                class="hyperchain"/></a
            >查詢。
          </p>
          <br /><iframe
            width="690"
            height="384"
            style="z-index:7;position:relative;margin-top: 10px;"
            src="https://www.youtube.com/embed/IbwLVm7oI5w?start=2"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <p class="iframesrc" style="margin-bottom:38px;">
            影片來源／照護線上
          </p>
        </div>
      </div>
    </div>
    <div class="qalist" id="qa11">
      <div
        class="closedStatus"
        @click="showtheLog11()"
        v-show="this.qa11 == false"
      >
        <img
          src="../assets/laptop/1024_q11/title+.png"
          srcset="
            ../assets/laptop/1024_q11/title+@2x.png 2x,
            ../assets/laptop/1024_q11/title+@3x.png 3x
          "
          id="closelog"
        />
      </div>
      <div class="openStatus" v-show="this.qa11">
        <img
          src="../assets/laptop/1024_q11/title-.png"
          srcset="
            ../assets/laptop/1024_q11/title-@2x.png 2x,
            ../assets/laptop/1024_q11/title-@3x.png 3x
          "
          class="blocker"
          @click="showtheLog11()"
        />
        <div class="openlog slideblue">
          <p style=" z-index:5; position:relative;">
            用藥有任何不良反應，須立即與醫療人員反應，並討論後續因應措施。<br />
            嚴謹的藥品安全監視程序對於任何生物製劑都非常重要，醫師與藥師一發現藥品不良反應，亦會主動聯繫全國藥物不良反應通報中心，為生物藥建立安全防護網。
          </p>
        </div>
      </div>
    </div>
    <div class="qalist" id="qa12">
      <div
        class="closedStatus"
        @click="showtheLog12()"
        v-show="this.qa12 == false"
      >
        <img
          src="../assets/laptop/1024_q12/title+.png"
          srcset="
            ../assets/laptop/1024_q12/title+@2x.png 2x,
            ../assets/laptop/1024_q12/title+@3x.png 3x
          "
          id="closelog"
        />
      </div>
      <div class="openStatus" v-show="this.qa12">
        <img
          src="../assets/laptop/1024_q12/title-.png"
          srcset="
            ../assets/laptop/1024_q12/title-@2x.png 2x,
            ../assets/laptop/1024_q12/title-@3x.png 3x
          "
          class="blocker"
          @click="showtheLog12()"
        />
        <div class="openlog slideblue">
          <p style=" z-index:5; position:relative;margin-top: 55px;">
            用藥安全應由政府、藥廠、醫療人員、病人等共同把關。
          </p>
          <p class="mainparagraph">
            <b>【政府】</b>
          </p>
          <p class="subparagraph">
            ◎ 做好監督與品質把關<br />
            <span style="margin-left: 31px;"
              >確保生物相似性藥品的製程中，嚴謹看待每一環節、兼顧藥品品質要求。</span
            ><br />
            ◎ 藥品上市後風險管理計畫的監督單位<br />
            <span style="margin-left: 31px;"
              >提供明確的「藥品不良反應通報」系統，監測副作用及療效。</span
            ><br />
            ◎ 清楚的藥品辨識<br />
            <span style="margin-left: 31px"
              >‧
              規範容易追蹤的命名，「商品名＋學名」，副作用/藥害發生時，容易追蹤。</span
            ><br />
            <span style="margin-left: 31px"
              >‧ 生物相似性藥品之仿單，註記生物相似性藥品等字樣以做區分。</span
            >
          </p>
          <p class="mainparagraph">
            <b>【原廠生物藥．生物相似藥 藥廠】</b>
          </p>
          <p class="subparagraph">
            ◎ 藥品風險管理計畫<br />
            <span style="margin-left: 31px;"
              >‧
              藥商應提出藥品風險管理計畫，當藥品核准上市時，該藥品安全監測系統及程序，
            </span>
            <br />
            <span style="margin-left: 49px;">須已完備並可立即實施。</span>
            <br />
            <span style="margin-left: 31px"
              >‧
              上市許可之藥商，須持續嚴密監測藥品之臨床安全性，負起藥品安全監測的責任。</span
            >
          </p>
          <p class="mainparagraph">
            <b>【醫院．醫療人員】</b>
          </p>
          <p class="subparagraph">
            ◎ 用藥資訊透明、善盡告知義務<br />
            <span style="margin-left: 31px"
              >醫師應告知病人生物相似藥的相關問題及疑慮，由病人做決定是否使用。</span
            ><br />
            ◎ 與病人一同監督政府。
          </p>
          <p class="mainparagraph">
            <b>【病人】</b>
          </p>
          <p class="subparagraph">
            ◎ 主動詢問、提出疑慮、自行監測副作用<br />
            <span style="margin-left: 31px; display: block"
              >除了醫師需盡到告知的義務，讓病人知道自身的用藥是何種藥品，而病人也需將用藥後的狀況回報給醫師，才能在藥品發生不良反應時，及時通報及時解決。</span
            >
          </p>
          <p class="mainparagraph">
            <b>【病友團體】</b>
          </p>
          <p class="subparagraph">
            ◎ 衛教病人‧提供諮詢。<br />
            ◎ 與病人一同監督政府。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "qna1024",
  data() {
    return {
      theLog: "",
      qa1: false,
      qa2: false,
      qa3: false,
      qa4: false,
      qa5: false,
      qa6: false,
      qa7: false,
      qa8: false,
      qa9: false,
      qa10: false,
      qa11: false,
      qa12: false,
    };
  },
  methods: {
    showtheLog1() {
      this.qa1 = !this.qa1;
    },
    showtheLog2() {
      this.qa2 = !this.qa2;
    },
    showtheLog3() {
      this.qa3 = !this.qa3;
    },
    showtheLog4() {
      this.qa4 = !this.qa4;
    },
    showtheLog5() {
      this.qa5 = !this.qa5;
    },
    showtheLog6() {
      this.qa6 = !this.qa6;
    },
    showtheLog7() {
      this.qa7 = !this.qa7;
    },
    showtheLog8() {
      this.qa8 = !this.qa8;
    },
    showtheLog9() {
      this.qa9 = !this.qa9;
    },
    showtheLog10() {
      this.qa10 = !this.qa10;
    },
    showtheLog11() {
      this.qa11 = !this.qa11;
    },
    showtheLog12() {
      this.qa12 = !this.qa12;
    },
  },
};
</script>

<style scoped>
.qna {
  height: auto;
  width: 100%;
  margin-top: 100px;
}
.qalist {
  width: 100%;
  margin: 10px auto;
  display: block;
}
.closedStatus {
  margin: 5% auto;
}
#closelog:hover {
  -webkit-filter: sepia(86%) saturate(495%) hue-rotate(326deg);
  filter: sepia(86%) saturate(495%) hue-rotate(326deg);
}
.openStatus {
  display: inline-block;
  width: auto;
  margin: 1% auto;
  /* border:2px solid black; */
  border-radius: 30px 30px 0px 0px;
  overflow: hidden;
}

p {
  text-align: left;
  margin: 4% auto;
  width: 860px;
  font-size: 17px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.87;
  letter-spacing: 2.4px;
  color: #ffffff;
}
.mainparagraph {
  width: 840px;
  z-index: 5;
  position: relative;
  margin: 5px auto 10px auto;
}
.subparagraph {
  z-index: 5;
  position: relative;
  margin: 5px auto 25px auto;
  width: 760px;
  margin-left: 9%;
}
.openlog {
  display: block;
  width: 99.5%;
  height: auto;
  padding-top: 38px;
  background-color: #6689c5;
  border: rgb(122, 150, 197) 3px solid;
}
.blocker {
  position: relative;
  z-index: 4;
  /* border:2px solid black; */
  border-radius: 20px 20px 0px 0px;
  margin: 0 auto;
  overflow: hidden;
}
.closedStatus:hover,
.blocker:hover {
  cursor: pointer;
}

@keyframes showup {
  0% {
    transform: translateY(-104px);
    opacity: 0;
  }
  60% {
    transform: translateY(-94px);
    opacity: 0;
  }
  100% {
    transform: translateY(-84px);
    opacity: 1;
  }
}
@-webkit-keyframes showup {
  0% {
    transform: translateY(-104px);
    opacity: 0;
  }
  60% {
    transform: translateY(-94px);
    opacity: 0;
  }
  100% {
    transform: translateY(-84px);
    opacity: 1;
  }
}

.slideblue {
  position: relative;
  z-index: 1;
  /* margin: 0 auto; */
  margin: 3% auto 0 auto;
  opacity: 0;
  /* border-radius: 40px 40px 0px 0px; */
  border-radius: 40px 40px 30px 30px;
  animation: showup 0.6s ease-in forwards;
  -webkit-animation: showup 0.6s ease-in forwards;
  animation-delay: 0s;
  -webkit-animation-delay: 0s;
}

@keyframes show {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes show {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
a,
#q5img,
iframe {
  opacity: 0;
  animation: show 0.6s ease-in forwards;
  -webkit-animation: show 0.6s ease-in forwards;
  animation-delay: 0s;
  -webkit-animation-delay: 0s;
}
.iframesrc {
  text-align: center;
  position: relative;
  z-index: 8;
  font-size: 11px;
  margin: auto;
  color: rgba(250, 235, 215, 0.813);
}
.hyperblank {
  padding: 3px 5px;
  margin: auto 2px;
  background: rgba(255, 255, 255, 0.176);
  color: #fce68d;
  text-decoration: none;
}
.hyperblank:hover {
  cursor: pointer;
  color: rgb(251, 210, 127);
}
.hyperchain {
  margin: auto 0;
  width: 16px;
  height: auto;
}
</style>
