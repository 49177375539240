<template>
  <div class="PCheader">
    <div class="main">
      <a target="_blank" href="https://www.canceraway.org.tw/">
        <img
          src="../assets/laptop/footer/footerlogo.png"
          srcset="
            ../assets/laptop/footer/footerlogo@2x.png 2x,
            ../assets/laptop/footer/footerlogo@3x.png 3x
          "
          id="logo"
        />
      </a>
    </div>
    <div class="social">
      <a
        target="_blank"
        href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fbiosimilar.canceraway.org.tw%2F&amp;src=sdkpreparse"
        class="fb-xfbml-parse-ignore"
      >
        <img
          src="../assets/laptop/footer/f-fb.png"
          srcset="
            ../assets/laptop/footer/f-fb@2x.png 2x,
            ../assets/laptop/footer/f-fb@3x.png 3x
          "
          id="facebook"
        />
      </a>
      <a
        target="_blank"
        href="https://social-plugins.line.me/lineit/share?url=https://biosimilar.canceraway.org.tw/"
      >
        <img
          src="../assets/laptop/footer/f-line.png"
          srcset="
            ../assets/laptop/footer/f-line@2x.png 2x,
            ../assets/laptop/footer/f-line@3x.png 3x
          "
          id="line"
      /></a>
    </div>
  </div>
</template>

<script>
export default {
  name: "PCheader",
};
</script>

<style scoped>
.PCheader {
  height: auto;
  width: 100%;
  margin-top: 24px;
  display: inline-block;
  position: relative;
  background-size: contain;
  background: transparent;
}
.main,
.social a {
  display: inline-block;
}
a:hover {
  cursor: pointer;
  z-index: 10;
}
.main {
  float: left;
  position: relative;
  z-index: 30;
}
#logo {
  width: auto;
  height: auto;
  margin-left: 33px;
}
.social {
  float: right;
}
#facebook {
  width: auto;
  height: auto;
  margin-right: 32px;
}
/* #twitter {
  width: 24px;
  height: 19px;
  margin-right: 27px;
} */
#line {
  width: auto;
  height: auto;
  margin-right: 27px;
}
</style>
