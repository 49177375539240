<template>
  <div class="PCfooter">
    <div class="footerline">
      <img
        src="../assets/laptop/footer/footer-l.png"
        srcset="
          ../assets/laptop/footer/footer-l@2x.png 2x,
          ../assets/laptop/footer/footer-l@3x.png 3x
        "
        id="borderline"
      />
    </div>
    <div class="footercontent">
      <a
        target="_blank"
        class="leftcontent"
        href="https://www.canceraway.org.tw/"
      >
        <img
          src="../assets/laptop/footer/footerlogo.png"
          srcset="
            ../assets/laptop/footer/footerlogo@2x.png 2x,
            ../assets/laptop/footer/footerlogo@3x.png 3x
          "
          id="logo"
        />
      </a>
      <div class="centercontent">
        <p style="margin: auto 5%;">諮詢專線</p>
        <a href="tel:+886-287873881"
          ><p><b>02-8787-3881</b></p></a
        >
        <p style="margin: auto 1%;">|</p>
        <a href="tel:+886-73113139"
          ><p><b>07-311-3139</b></p></a
        >
      </div>
      <div class="rightcontent">
        <p style="margin: auto 2%;">歡迎分享</p>
        <a
          target="_blank"
          href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fbiosimilar.canceraway.org.tw%2F&amp;src=sdkpreparse"
        >
          <img
            src="../assets/laptop/footer/f-fb.png"
            srcset="
              ../assets/laptop/footer/f-fb@2x.png 2x,
              ../assets/laptop/footer/f-fb@3x.png 3x
            "
            id="facebook"
          />
        </a>
        <a
          target="_blank"
          href="https://social-plugins.line.me/lineit/share?url=https://biosimilar.canceraway.org.tw/"
        >
          <img
            src="../assets/laptop/footer/f-line.png"
            srcset="
              ../assets/laptop/footer/f-line@2x.png 2x,
              ../assets/laptop/footer/f-line@3x.png 3x
            "
            id="line"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PCfooter",
};
</script>

<style scoped>
.PCfooter {
  margin: 5% auto 8% auto;
  height: auto;
  width: 100%;
  display: inline-block;
  position: relative;
  background-size: contain;
  background: transparent;
}
.footerline,
.footercontent {
  width: 100%;
  height: auto;
}
#borderline {
  width: 95%;
  height: auto;
  margin: 1% auto 3% auto;
}
p {
  font-size: 0.9em;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1.98px;
  color: #4c75ad;
}

a,
p,
.leftcontent,
.centercontent,
.rightcontent {
  margin: auto;
  -webkit-vertical-align: -webkit-baseline-middle;
  vertical-align: middle;
  display: inline-block;
}
.leftcontent {
  width: 10%;
  position: absolute;
  left: 4%;
}
.centercontent {
  width: 30%;
  position: absolute;
  left: 35%;
  margin-top: 7px;
}
.rightcontent {
  width: 20%;
  position: absolute;
  right: 0%;
}
#logo,
#facebook,
#line {
  vertical-align: text-top;
  width: auto;
  height: auto;
}
#facebook {
  margin: auto 20px;
}
@media screen and (max-width: 1080px) {
  .centercontent {
    width: 40%;
    position: absolute;
    left: 32%;
    margin-top: 7px;
  }
  .rightcontent {
    width: 20%;
    position: absolute;
    right: 2%;
  }
}
</style>
