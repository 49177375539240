<template>
  <div class="PCref">
    <b>【 參考文獻／專家諮詢 】</b>
    <p class="ref_paragraph">
      鄒玫君(2013)<a
        target="_blank"
        href="https://www.fda.gov.tw/tc/includes/GetFile.ashx?id=f636694214931524687"
        style="color:#255dab;"
        >《新藥審查》</a
      >取自衛生福利部食品藥物管理署・食品藥物管理局／ Robyn M. Boyle,
      RPh(2010)<a
        target="_blank"
        href="https://www.uspharmacist.com/article/the-use-of-biologics-in-cancer-therapy"
        style="color:#255dab;"
        >《The Use of Biologics in Cancer Therapy》</a
      >Retrieved from U.S. Pharmacist, Website／
      <a
        target="_blank"
        href="https://www.fda.gov.tw/tc/includes/GetFile.ashx?id=f636979305675005119"
        style="color:#255dab;"
        >《台灣生物相似性藥品之審核重點暨核准現況》</a
      >取自衛生福利部食品藥品管理署・食品藥物管理局／ Lowell J. Schiller
      (2019)<a
        target="_blank"
        href="https://www.federalregister.gov/documents/2019/05/14/2019-10001/considerations-in-demonstrating-interchangeability-with-a-reference-product-guidance-for-industry"
        style="color:#255dab;"
        >《Considerations in Demonstrating Interchangeability With a Reference
        Product; Guidance for Industry》</a
      >Retrieved from Food and Drug Administration／
      <a
        target="_blank"
        href="https://www.fda.gov.tw/tc/includes/GetFile.ashx?id=f636694164976448256"
        style="color:#255dab;"
        >《生物相似性藥品查驗登記基準(2015)》</a
      >取自衛生福利部食品藥物管理署・食品藥物管理局／ 陳培英(2017)<a
        target="_blank"
        href="https://www.gvm.com.tw/article/40358"
        style="color:#255dab;"
        >《如何對「生物相似藥」把關？》</a
      >取自遠見雜誌／ 台灣醫藥品法規學會蕭美玲理事長／
      生物相似藥推廣計畫主持人姜紹青副主任
    </p>
  </div>
</template>

<script>
export default {
  name: "PCref",
};
</script>

<style scoped>
.PCref {
  margin: 5% auto 8% auto;
  height: auto;
  width: 80%;
  display: inline-block;
  position: relative;
  background-size: contain;
  background: transparent;
}

b {
  font-size: 1.1em;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1.98px;
  color: #4c75ad;
  background: #fff8f3;
  padding: 1% 2%;
}
p {
  text-align: left;
  margin: -1% auto;
  width: 84%;
  font-size: 0.9em;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1.98px;
  color: #4c75ad;
  border: 1px solid rgba(96, 127, 170, 0.553);
  border-radius: 60px 60px;
  padding: 4% 5% 4% 5%;
}

@media screen and (max-width: 1040px) {
  .PCref {
    margin: 3% auto 2% auto;
    width: 96%;
  }
  p {
    font-size: 0.4em;
    margin: -1% auto;
    width: 86%;
    padding: 4% 5% 4% 5%;
  }
}
@media screen and (min-width: 1920px) {
  .PCref {
    margin: 5% auto 2% auto;
    width: 86%;
  }
  p {
    margin: -1% auto;
    width: 84%;
    padding: 4% 5% 4% 5%;
  }
}
@media screen and (min-width: 2560px) {
  .PCref {
    margin: 5% auto 2% auto;
    width: 65%;
  }
  p {
    margin: -1% auto;
    width: 84%;
    padding: 4% 5% 4% 5%;
  }
}
</style>
