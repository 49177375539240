<template>
  <div class="PCcontent">
    <div class="pctext">
        <img src="../assets/laptop/TITLE/TITLE.png"
          srcset="../assets/laptop/TITLE/TITLE@2x.png 2x,
                  ../assets/laptop/TITLE/TITLE@3x.png 3x"
                id="text"> 
        <p class="textp">
          近年來，隨著治療癌症的原廠生物製劑藥物專利陸續到期，其他藥廠紛紛開發與原廠生物製劑相仿的「生物相似藥」生物製劑，提供病友不同用藥選擇。
          <!-- 然而生物製劑的結構複雜，製造過程中若有些許不同，就可能導致結構、純度或有效度的不同，使得生物相似藥遭受不少質疑。 -->
        </p>
    </div>
    <div class="boysholder">
        <img src="../assets/laptop/questionmark/q.png"
          srcset="../assets/laptop/questionmark/q@2x.png 2x,
                  ../assets/laptop/questionmark/q@3x.png 3x"
              id="questionmark"><br>
        <img src="../assets/laptop/boybottle1/b-sss-1.png"
          srcset="../assets/laptop/boybottle1/b-sss-1@2x.png 2x,
                  ../assets/laptop/boybottle1/b-sss-1@3x.png 3x"
              id="boybottle1" class="boyholder">
        <img src="../assets/laptop/intro/boy.png"
          srcset="../assets/laptop/intro/boy@2x.png 2x,
                  ../assets/laptop/intro/boy@3x.png 3x"
              id="boy" class="boyholder">
        <img src="../assets/laptop/boybottle2/b-sss-2.png"
          srcset="../assets/laptop/boybottle2/b-sss-2@2x.png 2x,
                  ../assets/laptop/boybottle2/b-sss-2@3x.png 3x"
              id="boybottle2" class="boyholder">  
    </div>            
    <div class="medholderONE">
        <img src="../assets/laptop/bt1-yellow/b-yellow-s.png"
          srcset="../assets/laptop/bt1-yellow/b-yellow-s@2x.png 2x,
                  ../assets/laptop/bt1-yellow/b-yellow-s@3x.png 3x"
              id="bt1" class="medcineONE">
        <img src="../assets/laptop/bt3-purple/b-purple.png"
          srcset="../assets/laptop/bt3-purple/b-purple@2x.png 2x,
                  ../assets/laptop/bt3-purple/b-purple@3x.png 3x"
              id="bt3" class="medcineONE">                 
    </div>
    <div class="medholderTWO">
        <img src="../assets/laptop/bt2-pink/b-pink-s.png"
          srcset="../assets/laptop/bt2-pink/b-pink-s@2x.png 2x,
                  ../assets/laptop/bt2-pink/b-pink-s@3x.png 3x"
              id="bt2" class="medcineTWO">
        <img src="../assets/laptop/bt4-green/b-green-s.png"
          srcset="../assets/laptop/bt4-green/b-green-s@2x.png 2x,
                  ../assets/laptop/bt4-green/b-green-s@3x.png 3x"
              id="bt4" class="medcineTWO">
        <img src="../assets/laptop/bt6-yellow/b-yellow-l.png"
          srcset="../assets/laptop/bt6-yellow/b-yellow-l@2x.png 2x,
                  ../assets/laptop/bt6-yellow/b-yellow-l@3x.png 3x"
              id="bt6" class="medcineTWO">
        <img src="../assets/laptop/bt5-pink/b-pink-m.png"
          srcset="../assets/laptop/bt5-pink/b-pink-m@2x.png 2x,
                  ../assets/laptop/bt5-pink/b-pink-m@3x.png 3x"
              id="bt5" class="medcineTWO">
        <img src="../assets/laptop/bt8-green/b-green-m.png"
          srcset="../assets/laptop/bt8-green/b-green-m@2x.png 2x,
                  ../assets/laptop/bt8-green/b-green-m@3x.png 3x"
              id="bt8" class="medcineTWO">
        <img src="../assets/laptop/bt7-yellow/b-yellow-m.png"
          srcset="../assets/laptop/bt7-yellow/b-yellow-m@2x.png 2x,
                  ../assets/laptop/bt7-yellow/b-yellow-m@3x.png 3x"
              id="bt7" class="medcineTWO">               
    </div>
        <img src="../assets/laptop/intro/doctor.png"
          srcset="../assets/laptop/intro/doctor@2x.png 2x,
                  ../assets/laptop/intro/doctor@3x.png 3x"
              id="doctor"> 
    <div class="pgbottom">
        <img src="../assets/laptop/intro/b-medicine.png"
          srcset="../assets/laptop/intro/b-medicine@2x.png 2x,
                  ../assets/laptop/intro/b-medicine@3x.png 3x"
              id="decrimedicine">
    <div class="pcbottom">
        <p class="textb">
          過去生物相似藥的開發，最常用於發炎性自體免疫疾病之治療，而近日國內陸續有生物相似藥使用於癌症標靶治療。
        </p>
        <p class="textb">
          面臨生物相似藥，病友可能面臨什麼問題？又該如何了解自身權益？透過以下問題集，讓台灣癌症基金會帶著你，從生物製劑、原廠生物製劑與生物相似藥開始認識，一起為自己的用藥安全把關。
        </p>
        <img src="../assets/laptop/intro/line-2.png"
          srcset="../assets/laptop/intro/line-2@2x.png 2x,
                  ../assets/laptop/intro/line-2@3x.png 3x"
              id="decriline">
    </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PCcontent1680',
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Kosugi+Maru&display=swap');
.PCcontent{
  height: auto;
  width: 1680px;
  display: inline-block;
  position: relative;
  background-size: contain;
  background: transparent;
}
.pctext{
  padding:0;
  display: block;
  float:right;
  width: 45.6%;
  margin: 68px 146px auto auto;
}

@keyframes showtext{
    0%   { transform: translateY(-14px);}
    100% { opacity: 1;}} 
@-webkit-keyframes showtext {
    0%   { transform: translateY(-14px);}
    100% { opacity: 1;}} 
#text{
  text-align: right;
  width: 770px;
}
.textp{
  margin: 40px -10px auto auto;
  float: right;
  text-align:left;
  width: 612px;
}
p {
  /* width: 347px; */
  /* font-family: TaipeiSansTCBeta; */
  font-size: 17px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.87;
  letter-spacing: 1.98px;
  color: #4C75AD;
  opacity: 0;
  animation: showtext 1s ease-in-out forwards;
  -webkit-animation: showtext 1s ease-in-out forwards;
  animation-delay: 0.4s;
	-webkit-animation-delay: 0.4s;
}
#questionmark {
  padding:15px 15px;
  margin: 107px auto 5px auto;
  width: auto;
  height: 57px;
  object-fit: contain;
  position: relative;
  z-index: 2;
}
@keyframes questionmove{
    0%  { transform: rotate(20deg); }
    50% { transform: rotate(-20deg);}
    100%{ transform: rotate(20deg); }}
@-webkit-keyframes questionmove {
    0%  { transform: rotate(20deg); }
    50% { transform: rotate(-20deg);}
    100%{ transform: rotate(20deg); }}
/* #questionmark:hover {
  animation: questionmove 1.5s ease-in-out infinite;
  -webkit-animation: questionmove 1.5s ease-in-out infinite;
  animation-delay: 0s;
	-webkit-animation-delay: 0s;
} */
#questionmark {
  animation: questionmove 2.5s ease-in-out infinite;
  -webkit-animation: questionmove 2.5s ease-in-out infinite;
  animation-delay: 0s;
	-webkit-animation-delay: 0s;
}
@keyframes movedown{
    0%   { transform: translateY(-14px);}
    100% { opacity: 1;}} 
@-webkit-keyframes movedown {
    0%   { transform: translateY(-14px);}
    100% { opacity: 1;}} 
.boysholder{
  display: inline-block;
  width: 240px;
  margin: 0px -55px 0 40px;
  opacity: 0;
  animation: movedown 0.4s ease forwards;
  -webkit-animation: movedown 0.4s ease forwards;
  animation-delay: 2s;
	-webkit-animation-delay: 2s;
}
.boyholder{
  vertical-align: top;
  position: relative;
  display: inline;
}
#boybottle1{
  margin-top:50px;
  width: auto;
  height: 65px;
  object-fit: contain;
  position: relative;
  z-index: 2;
}
#boybottle2{
  margin-top:50px;
  width: auto;
  height: 65px;
  object-fit: contain;
  position: relative;
  z-index: 2;
}
/* @keyframes bottlemove{
    0%  { transform: scale(1,1);    }
    50% { transform: scale(1.2,1.2);}
    100%{transform: scale(1,1);     }}
@-webkit-keyframes bottlemove {
    0%  { transform: scale(1,1);    }
    50% { transform: scale(1.2,1.2);}
    100%{transform: scale(1,1);     }} */
@keyframes bottlemovePINK{
     0%  { transform: scale(1,1);    }    
    25%  { transform: scale(1,1);    }
    50%  { transform: scale(1,1);    }
    75% { transform: scale(1.2,1.2);}
    100%{transform: scale(1,1);     }}
@-webkit-keyframes bottlemovePINK {
     0%  { transform: scale(1,1);    }    
    25%  { transform: scale(1,1);    }
    50%  { transform: scale(1,1);    }
    75% { transform: scale(1.2,1.2);}
    100%{transform: scale(1,1);     }}
@keyframes bottlemoveBLUE{
    0%  { transform: scale(1,1);    }
    25% { transform: scale(1.2,1.2);}
    50%{transform: scale(1,1);     }
    75%{transform: scale(1,1);     }
    100%{transform: scale(1,1);     }}
@-webkit-keyframes bottlemoveBLUE {
    0%  { transform: scale(1,1);    }
    25% { transform: scale(1.2,1.2);}
    50%{transform: scale(1,1);     }
    75%{transform: scale(1,1);     }
    100%{transform: scale(1,1);     }}
/* #boybottle1:hover ,#boybottle2:hover {
  animation: bottlemove 1.5s ease-in-out infinite;
  -webkit-animation: bottlemove 1.5s ease-in-out infinite;
  animation-delay: 0s;
	-webkit-animation-delay: 0s;
} */
#boybottle1{
  animation: bottlemovePINK 2.5s linear infinite;
  -webkit-animation: bottlemovePINK 2.5s linear infinite;
  animation-delay: 0s;
	-webkit-animation-delay: 0s;
}
#boybottle2 {
  animation: bottlemoveBLUE 2.5s linear infinite;
  -webkit-animation: bottlemoveBLUE 2.5s linear infinite;
  animation-delay: 0s;
	-webkit-animation-delay: 0s;
}
#boy{
  margin-right:-15px;
  margin-left:-15px;
  width: auto;
  height: 300px;
  object-fit: contain;
  position: relative;
  z-index: 0;
}
@keyframes showup{
    0%   { transform: translateY(18px);}
    100% { opacity: 1;}} 
@-webkit-keyframes showup {
    0%   { transform: translateY(18px);}
    100% { opacity: 1;}} 
.medholderONE{
  width: 660px;
  height: auto;
  object-fit: contain;
  position: relative;
  z-index: 0;
  margin-left: 0;
  margin-top: -5px;
  margin-bottom: -580px;
}
.medcineONE{
  display: inline-block;
}
#bt1{
  margin-bottom:-24px;
  margin-left: 0px;
  width: auto;
  height: 156px;  
  opacity: 0;
  animation: showup 1.2s ease forwards;
  -webkit-animation: showup 1.2s ease forwards;
  animation-delay: 0.8s;
	-webkit-animation-delay: 0.8s;
}
#bt3{
  margin-left: 128px;
  width: auto;
  height: 272px;
  opacity: 0;
  animation: showup 0.8s ease forwards;
  -webkit-animation: showup 0.8s ease forwards;
  animation-delay: 0.3s;
	-webkit-animation-delay: 0.3s;
}
.medholderTWO{
  width: 900px;
  height: auto;
  margin:auto;
  margin-left: 0;
  margin-bottom: 0px;
  margin-top: 30px;
  vertical-align: top;
  display: inline-block;
  /* object-fit: contain; */
}
.medcineTWO{
  display: inline;
}
#bt2{
  width: auto;
  height: 231px;
  margin-left: -42px;
  margin-bottom: 50px;
  position: relative;
  z-index: 1;
  opacity: 0;
  animation: showup 1.2s ease forwards;
  -webkit-animation: showup 1.2s ease forwards;
  animation-delay: 0.4s;
	-webkit-animation-delay: 0.4s;
}
#bt4{
  width: auto;
  height: 207px;
  margin-left: -8px;
  margin-bottom: 80px;
  position: relative;
  z-index: 0;
  opacity: 0;
  animation: showup 1s linear forwards;
  -webkit-animation: showup 1s linear forwards;
  animation-delay: 2s;
	-webkit-animation-delay: 2s;
}
#bt6{
  width: auto;
  height: 255px;
  margin-left: 70px;
  margin-bottom: 12px;
  position: relative;
  z-index: 1;
  opacity: 0;
  animation: showup 1.2s ease forwards;
  -webkit-animation: showup 1.2s ease forwards;
  animation-delay: 1.6s;
	-webkit-animation-delay: 1.6s;
}
#bt5{
  width: auto;
  height: 462px;
  margin-bottom: 120px;
  margin-left: -40px;
  position: relative;
  z-index: 0;
  opacity: 0;
  animation: showup 1.2s ease forwards;
  -webkit-animation: showup 1.2s ease forwards;
  animation-delay: 1.4s;
	-webkit-animation-delay: 1.4s;
}
#bt8{
  width: auto;
  height: 206px;
  margin-left: -20px;
  margin-bottom: 68px;
  position: relative;
  z-index: 1;
  opacity: 0;
  animation: showup 1.2s linear forwards;
  -webkit-animation: showup 1.2s linear forwards;
  animation-delay: 1s;
	-webkit-animation-delay: 1s;
}
#bt7{
  width: auto;
  height: 217px;
  margin-bottom: 160px;
  margin-left: -20px;
  margin-right: 60px;
  position: relative;
  z-index: 0;
  opacity: 0;
  animation: showup 1.2s ease forwards;
  -webkit-animation: showup 1.2s ease forwards;
  animation-delay: 2.2s;
	-webkit-animation-delay: 2.2s;
}
@keyframes floating{
    0%   { transform: translateY(8px) rotate(4deg);}
    100% { transform: translateY(0px) rotate(0deg);
           opacity: 1;}} 
@-webkit-keyframes floating {
    0%   { transform: translateY(8px) rotate(4deg);}
    100% { transform: translateY(0px) rotate(0deg);
           opacity: 1;}} 
#doctor{
  width: auto;
  height: 852px;
  margin-top: 20px;
  margin-left: -80px;
  margin-bottom: 22px;
  display: inline-block;
  transform: rotate(0deg);
  opacity: 0;
  animation: floating 0.9s ease-in forwards;
  -webkit-animation: floating 0.9s ease-in forwards;
  animation-delay: 2.4s;
	-webkit-animation-delay: 2.4s;
}
/* #doctor:hover{
  animation: showup 1s ease-in infinite;
  -webkit-animation: showup 1s ease-in infinite;
  animation-delay: 0s;
	-webkit-animation-delay: 0s;
} */
.pgbottom{
  float: left;
  width: 65%;
  margin-top: -5%;
  margin-left: 6%;
}
#decrimedicine, .pcbottom{
  display: inline-block;
}
#decrimedicine{
  float: left;
  width: 17%;
  height: auto;
  vertical-align: bottom;
  margin-bottom:0;
  margin-right:0px;
}
#decriline{
  width: 770px;
  margin-bottom:0;
}
.textb{
  margin: 4% auto 2% auto;
  text-align:left;
  width: 650px;
}
.pcbottom{
  width: 80%;
  text-align:center;
  margin-bottom:0;
}
</style>

