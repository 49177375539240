<template>
  <div class="arrowbox">
  <!-- fullsizehome
        Width: {{ window.width }},
        Height: {{ window.height }} -->
      <svg class="arrows" v-if="(1440 < window.width)">
					<path class="a1" d="M0 0 L30 32 L60 0"></path>
					<path class="a2" d="M0 20 L30 52 L60 20"></path>
					<path class="a3" d="M0 40 L30 72 L60 40"></path>
			</svg>
      <svg class="arrows" v-else-if="(1440 >= window.width) && (window.width >= 1024)">
					<path class="a1" d="M0 0 L20 22 L40 0"></path>
					<path class="a2" d="M0 20 L20 42 L40 20"></path>
					<path class="a3" d="M0 40 L20 62 L40 40"></path>
			</svg>
  </div>
</template>

<script>
export default {
  name: 'arrowbox',
  data(){
    return{
      window: {
        width: 0,
        height: 0
      },
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    }
  }
}
</script>

<style scoped>
@keyframes show{
    0%   { opacity: 0;}
    100% { opacity: 1;}} 
@-webkit-keyframes show {
    0%   { opacity: 0;}
    100% { opacity: 1;}} 

.arrowbox{
  z-index: 20;
  height: 10%;
  width: auto;
  position: absolute;
  bottom: 5vh;
  left: 95vw;
  background-size: contain;
  background: transparent;
  opacity: 0;
  animation: show 1s linear forwards;
  -webkit-animation: show 1s linear forwards;
  animation-delay: 3s;
  -webkit-animation-delay: 3s;
}

.arrows {
	width: 60px;
	height: 92px;
	position: absolute;
	left: 50%;
	margin-left: -30px;
	bottom: -10px;
}

.arrows path {
	stroke: rgba(102, 137, 197, 0.8);
	fill: transparent;
	stroke-width: 4.4px;	
	animation: arrow 2s infinite;
	-webkit-animation: arrow 2s infinite; 
}

@keyframes arrow
{
0% {opacity:0}
40% {opacity:1}
80% {opacity:0}
100% {opacity:0}
}

@-webkit-keyframes arrow /*Safari and Chrome*/
{
0% {opacity:0}
40% {opacity:1}
80% {opacity:0}
100% {opacity:0}
}

.arrows path.a1 {
	animation-delay:-1s;
	-webkit-animation-delay:-1s; /* Safari 和 Chrome */
}

.arrows path.a2 {
	animation-delay:-0.5s;
	-webkit-animation-delay:-0.5s; /* Safari 和 Chrome */
}

.arrows path.a3 {	
	animation-delay:0s;
	-webkit-animation-delay:0s; /* Safari 和 Chrome */
}

</style>
