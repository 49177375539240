<template>
  <div class="MBfooter">
    <div class="sharing">
      <p class="title">歡迎分享</p>
      <div class="content">
        <a
          target="_blank"
          href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fbiosimilar.canceraway.org.tw%2F&amp;src=sdkpreparse"
        >
          <img
            src="../assets/mobile/320/fb.png"
            srcset="
              ../assets/mobile/320/fb@2x.png 2x,
              ../assets/mobile/320/fb@3x.png 3x
            "
            id="facebook"
          />
        </a>
        <a
          target="_blank"
          href="https://social-plugins.line.me/lineit/share?url=https://biosimilar.canceraway.org.tw/"
        >
          <img
            src="../assets/mobile/320/line.png"
            srcset="
              ../assets/mobile/320/line@2x.png 2x,
              ../assets/mobile/320/line@3x.png 3x
            "
            id="line"
          />
        </a>
      </div>
      <img
        src="../assets/mobile/320/l.png"
        srcset="
          ../assets/mobile/320/l@2x.png 2x,
          ../assets/mobile/320/l@3x.png 3x
        "
        id="borderline"
      />
      <a target="_blank" href="https://www.canceraway.org.tw/">
        <img
          src="../assets/mobile/320/logofooter.png"
          srcset="
            ../assets/mobile/320/logofooter@2x.png 2x,
            ../assets/mobile/320/logofooter@3x.png 3x
          "
          id="logo"
        />
      </a>
      <p style="margin-bottom:-5px;">諮詢專線</p>
      <p style="font-size:14px;">
        <a href="tel:+886-287873881" style="color:#4C75AD"
          ><b>02-8787-3881</b></a
        >
        |
        <a href="tel:+886-73113139" style="color:#4C75AD"><b>07-311-3139</b></a>
      </p>
    </div>
    <div class="ref">
      <p id="ref_bold">【 參考文獻／專家諮詢 】</p>
      <p class="ref_paragraph">
        鄒玫君(2013)<a
          target="_blank"
          href="https://www.fda.gov.tw/tc/includes/GetFile.ashx?id=f636694214931524687"
          style="color:#255dab;"
          >《新藥審查》</a
        >取自衛生福利部食品藥物管理署・食品藥物管理局／ Robyn M. Boyle,
        RPh(2010)<a
          target="_blank"
          href="https://www.uspharmacist.com/article/the-use-of-biologics-in-cancer-therapy"
          style="color:#255dab;"
          >《The Use of Biologics in Cancer Therapy》</a
        >Retrieved from U.S. Pharmacist, Website／
        <a
          target="_blank"
          href="https://www.fda.gov.tw/tc/includes/GetFile.ashx?id=f636979305675005119"
          style="color:#255dab;"
          >《台灣生物相似性藥品之審核重點暨核准現況》</a
        >取自衛生福利部食品藥品管理署・食品藥物管理局／ Lowell J. Schiller
        (2019)<a
          target="_blank"
          href="https://www.federalregister.gov/documents/2019/05/14/2019-10001/considerations-in-demonstrating-interchangeability-with-a-reference-product-guidance-for-industry"
          style="color:#255dab;"
          >《Considerations in Demonstrating Interchangeability With a Reference
          Product; Guidance for Industry》</a
        >Retrieved from Food and Drug Administration／
        <a
          target="_blank"
          href="https://www.fda.gov.tw/tc/includes/GetFile.ashx?id=f636694164976448256"
          style="color:#255dab;"
          >《生物相似性藥品查驗登記基準(2015)》</a
        >取自衛生福利部食品藥物管理署・食品藥物管理局／ 陳培英(2017)<a
          target="_blank"
          href="https://www.gvm.com.tw/article/40358"
          style="color:#255dab;"
          >《如何對「生物相似藥」把關？》</a
        >取自遠見雜誌／台灣醫藥品法規學會蕭美玲理事長／
        生物相似藥推廣計畫主持人姜紹青副主任
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "MBfooter",
};
</script>

<style scoped>
.MBfooter {
  margin: 5% auto 8% auto;
  height: auto;
  width: 100%;
  display: inline-block;
  position: relative;
  background-size: contain;
  background: transparent;
}
.sharing {
  width: 100%;
  height: auto;
  text-align: center;
}
p {
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1.98px;
  color: #4c75ad;
}
.title {
  font-size: 19px;
  font-weight: 600;
  letter-spacing: 4.2px;
  line-height: 1.67;
}
.content {
  margin: auto;
  -webkit-vertical-align: -webkit-baseline-middle;
  vertical-align: middle;
  display: inline-block;
}
#facebook,
#line {
  width: auto;
  height: auto;
  margin: 5px 20px 15px 20px;
}
#borderline {
  width: 88%;
  margin: auto auto;
}
#logo {
  width: 55%;
  height: auto;
  margin: 25px auto;
}
.ref {
  margin: 5% auto 8% auto;
  height: auto;
  width: 80%;
  display: inline-block;
  position: relative;
  background-size: contain;
  background: transparent;
}

#ref_bold {
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1.98px;
  color: #4c75ad;
  background: #fff8f3;
  padding: 3% 4%;
  z-index: 5;
  display: inline-block;
}
.ref_paragraph {
  text-align: left;
  margin: -10% auto;
  width: 86%;
  font-size: 6px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1.98px;
  color: #4c75ad;
  border: 1px solid rgba(96, 127, 170, 0.553);
  border-radius: 20px 20px;
  padding: 4% 5% 4% 5%;
  z-index: 2;
}
</style>
