import { render, staticRenderFns } from "./PCcontent1680.vue?vue&type=template&id=65482886&scoped=true&"
import script from "./PCcontent1680.vue?vue&type=script&lang=js&"
export * from "./PCcontent1680.vue?vue&type=script&lang=js&"
import style0 from "./PCcontent1680.vue?vue&type=style&index=0&id=65482886&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65482886",
  null
  
)

export default component.exports