<template>
  <div id="home">
    <!-- fullsizehome
      Width: {{ window.width }},
      Height: {{ window.height }} -->
    <div
      id="pc"
      v-if="2560 >= window.width && window.width > 1920"
      :style="pcbackgroundDiv2560"
    >
      <PCheader />
      <PCcontent2560 />
      <arrowbox />
      <qna1920 />
      <PCref />
      <PCfooter />
    </div>
    <div
      id="pc"
      v-else-if="1920 >= window.width && window.width > 1680"
      :style="pcbackgroundDiv1920"
    >
      <PCheader />
      <PCcontent1920 />
      <arrowbox />
      <qna1920 />
      <PCref />
      <PCfooter />
    </div>
    <div
      id="pc"
      v-else-if="1680 >= window.width && window.width > 1440"
      :style="pcbackgroundDiv1680"
    >
      <PCheader />
      <PCcontent1680 />
      <arrowbox />
      <qna1440 />
      <PCref />
      <PCfooter />
    </div>
    <div
      class="pc1440"
      v-else-if="1440 >= window.width && window.width > 1024"
      :style="pcbackgroundDiv1440"
    >
      <PCheader />
      <PCcontent1440 />
      <arrowbox />
      <qna1440 />
      <PCref />
      <PCfooter />
    </div>
    <div
      id="pc"
      v-else-if="1024 >= window.width && window.width > 450"
      :style="pcbackgroundDiv1024"
    >
      <PCheader />
      <PCcontent1024 />
      <arrowbox />
      <qna1024 />
      <PCref />
      <PCfooter />
    </div>
    <div
      id="mobile"
      v-else-if="450 >= window.width && window.width > 375"
      :style="pcbackgroundDiv414"
    >
      <MBcontent414 />
      <qna414 />
      <MBfooter />
    </div>
    <div
      id="mobile"
      v-else-if="375 >= window.width && window.width > 300"
      :style="pcbackgroundDiv320"
    >
      <MBcontent320 />
      <qna320 />
      <MBfooter />
    </div>
  </div>
</template>

<script>
import PCheader from "@/components/PCheader.vue";
import arrowbox from "@/components/arrows.vue";
import PCcontent1024 from "@/components/PCcontent1024.vue";
import PCcontent1440 from "@/components/PCcontent1440.vue";
import PCcontent1680 from "@/components/PCcontent1680.vue";
import PCcontent1920 from "@/components/PCcontent1920.vue";
import PCcontent2560 from "@/components/PCcontent2560.vue";
import qna1024 from "@/components/qna1024.vue";
import qna1440 from "@/components/qna1440.vue";
import qna1920 from "@/components/qna1920.vue";
import PCfooter from "@/components/PCfooter.vue";
import PCref from "@/components/PCref.vue";
import MBcontent320 from "@/components/MBcontent320.vue";
import MBcontent414 from "@/components/MBcontent414.vue";
import qna320 from "@/components/qna320.vue";
import qna414 from "@/components/qna414.vue";
import MBfooter from "@/components/MBfooter.vue";
export default {
  components: {
    PCheader,
    arrowbox,
    PCfooter,
    PCcontent1024,
    PCcontent1440,
    PCcontent1680,
    PCcontent1920,
    PCcontent2560,
    qna1024,
    qna1440,
    qna1920,
    MBcontent414,
    qna414,
    MBcontent320,
    qna320,
    // PCbanner,
    PCref,
    // MBbanner,
    MBfooter,
  },
  data() {
    return {
      window: {
        width: 0,
        height: 0,
      },
      pcbackgroundDiv2560: {
        backgroundImage: "url(" + require("../assets/BG/bg_2560.png") + ")",
      },
      pcbackgroundDiv1920: {
        backgroundImage: "url(" + require("../assets/BG/bg_1920.png") + ")",
      },
      pcbackgroundDiv1680: {
        backgroundImage: "url(" + require("../assets/BG/bg_1680.png") + ")",
      },
      pcbackgroundDiv1440: {
        backgroundImage: "url(" + require("../assets/BG/bg_1440.png") + ")",
      },
      pcbackgroundDiv1024: {
        backgroundImage: "url(" + require("../assets/BG/bg_1024.png") + ")",
      },
      pcbackgroundDiv414: {
        backgroundImage: "url(" + require("../assets/BG/bg_414.png") + ")",
      },
      pcbackgroundDiv320: {
        backgroundImage: "url(" + require("../assets/BG/bg_375.png") + ")",
      },
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
  },
};
</script>

<style scoped>
#pc {
  font-family: "Kosugi Maru", sans-serif;
  font-weight: 400;
  font-style: normal;
  height: auto;
  width: 100%;
  background: no-repeat center top;
  background-size: cover;
}
.pc1440 {
  height: auto;
  width: 100%;
  background: no-repeat center top;
  background-size: cover;
}
</style>
